<template>
  <div id="promises-requests-edit">
    <b-modal
      centered
      hide-footer
      ref="modalPromises"
      title="Editar Estado">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Estado" label-for="status">
              <v-select
                v-model="editForm.name"
                label="name"
                placeholder="Selecciona un estado"
                :options="status"
                :reduce="status => status.id">
                <span slot="no-options">
                  No se encontraron opciones
                </span>
              </v-select>
              <small class="text-danger">{{ nameErrors[0] }}</small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-h="end">
          <div class="d-flex align-items-end justify-content-end mr-1 mt-1">
            <b-form-group>
              <div>
                <b-overlay
                  :show="overlayForm"
                  opacity="0.5"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block"
                  @hidden="_onHidden()">
                  <b-button block
                    ref="button"
                    :disabled="overlayForm"
                    variant="primary"
                    @click.prevent="_updatePromisesRequests()">
                    <feather-icon
                      icon="SaveIcon"
                      class="mr-50"/>
                    <span class="align-middle">
                      Guardar
                    </span>
                  </b-button>
                </b-overlay>
              </div>
            </b-form-group>
          </div>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'
  import {required} from 'vuelidate/lib/validators'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    name: 'PromisesRequestsEdit',
    data(){
      return {
        overlayForm: false,
        editForm: {
          id: 1,
          name: '',
        },
        status: [
          {id: 'canceled',name:'Cancelada'},
          {id: 'pending',name:'Pendiente'},
          {id: 'done',name:'Aprobada'}
        ],
      }
    },
    validations: {
      editForm: {
        name: {
          required,
        },
      },
    },
    computed: {
      nameErrors () {
        const errors = []
        if (!this.$v.editForm.name.$dirty) return errors
        !this.$v.editForm.name.required && errors.push('El nombre es obligatorio')
        return errors
      },
    },
    methods: {
      ...mapActions('promises',['updatePromiseOrder']),
      async _updatePromisesRequests () {
        this.$v.editForm.$touch()
        if (!this.$v.editForm.$invalid) {
          this.overlayForm = true
          await this.updatePromiseOrder(this.editForm)
          .then(res => {
            this._hideModal();
            this.editForm = {name: ''}
            this.$v.editForm.$reset()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Estado modificado con éxito',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
          }).catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Problemas al modificar el estado',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }).finally(() => {
            this.overlayForm = false
          })
        }
      },
      _showModal(data) {
        this.editForm.id = data.id
        this.editForm.name = data.status
        this.$refs['modalPromises'].show()
      },
      _hideModal() {
        this.$refs['modalPromises'].hide()
      },
      _onHidden() {
        this.$refs.button.focus()
      },
    }
  }
</script>
