<template>
  <div id="promises-requests">
    <promises-requests-list></promises-requests-list>
  </div>
</template>

<script>
  import PromisesRequestsList from '../promises/components/PromisesRequestsList'

  export default{
    name: 'PromisesRequestsView',
    components: {PromisesRequestsList}
  }
</script>
